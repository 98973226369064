import type { MongoAbility } from '@casl/ability'
import { AbilityBuilder, createMongoAbility } from '@casl/ability'
import { createContextualCan } from '@casl/react'
import { createContext } from 'react'
import type { UserProfile } from '~/types/auth'

export type Action = 'read'
export type Subject =
  // Tool subjects
  | 'trade-lanes'
  | 'trade-lanes-statistics'
  | 'trade-lane-builder'
  | 'shipments'
  | 'optimizer'
  | 'optimizer-pricing'
  | 'importer'

  // Routescanner subjects
  | 'co-sharing'
  | 'schedule-builder'
  | 'beta-feature'

export type AppAbilities = MongoAbility<[Action, Subject]>

export function defineAbilityFor(user: UserProfile | null) {
  const { can, build } = new AbilityBuilder<AppAbilities>(createMongoAbility)

  if (!user) {
    return build()
  }

  // Schedule builder
  if (user.roles.includes('Schedule builder external user') || user.roles.includes('Schedule builder administrator')) {
    can('read', 'schedule-builder')
  }

  // Co-sharing
  if (user.roles.includes('Routescanner administrator')) {
    can('read', 'co-sharing')
  }

  // Beta feature
  if (user.roles.includes('Beta Tester')) {
    can('read', 'beta-feature')
  }

  // Beta feature
  if (user.roles.includes('FE optimizer-pricing')) {
    can('read', 'optimizer-pricing')
  }

  // Organization maintainers can do everything in the tool
  if (user.roles.includes('Organization maintainer')) {
    can('read', ['trade-lanes-statistics', 'shipments', 'optimizer'])

    if (user.organizationId) {
      can('read', ['trade-lanes', 'trade-lane-builder', 'importer'])
    }
  }

  // Individual roles for the different subjects
  if (user.roles.includes('FE trade-lanes') && !!user.organizationId) {
    can('read', 'trade-lanes')
  }

  if (user.roles.includes('FE trade-lanes-statistics')) {
    can('read', 'trade-lanes-statistics')
  }

  if (user.roles.includes('FE trade-lane-builder')) {
    can('read', 'trade-lane-builder')
  }

  if (user.roles.includes('FE shipments')) {
    can('read', 'shipments')
  }

  if (user.roles.includes('FE optimizer')) {
    can('read', 'optimizer')
  }

  if (user.roles.includes('FE importer')) {
    can('read', 'importer')
  }

  return build()
}

export const AbilityContext = createContext(defineAbilityFor(null))
export const Can = createContextualCan(AbilityContext.Consumer)
